import { useContext, useEffect } from "react";

import Part from "./Part";
import { LessonContext } from "./Lesson";
import { removeWhitespace } from "./utils";

function Reader() {
  const {
    content,
    currentPart,
    hasBeenSplit,
    parts,
    showEverything,
    isReview,
    setLesson,
  } = useContext(LessonContext);

  const { markdown } = content;

  useEffect(() => {
    if (hasBeenSplit) {
      return;
    }

    // Split by markdown/example/exercise
    const breakpoint = /(@(?:example|exercise|quiz)=[a-z0-9-]+)/;
    const byType = markdown.split(breakpoint);
    const byTypeClean = byType.map((part) => removeWhitespace(part)).flat(3);

    // Split by <hr> separator
    const byVisibility = byTypeClean
      .map((item) => {
        const points = /(?:\n\n|---)/;
        return item.split(points);
      })
      .flat(5);

    const byVisibilityClean = byVisibility
      .map((part) => removeWhitespace(part))
      .flat(3)
      .filter((item) => item !== "")
      .flat(3);

    const byKind = byVisibilityClean.map((item) => {
      if (item.startsWith("@exercise")) {
        return [
          {
            kind: "markdown",
            content: "Here's an exercise:",
          },
          {
            kind: "exercise",
            content: item,
          },
        ];
      }

      let kind = "markdown";

      if (item.startsWith("<")) {
        kind = "html";
      } else if (item.startsWith("@example")) {
        kind = "example";
      } else if (item.startsWith("@quiz")) {
        return [
          {
            kind: "markdown",
            content: "Here's a question:",
          },
          {
            kind: "exercise",
            content: item,
          },
        ];
      }

      return {
        kind,
        content: item,
      };
    });

    const byListItem = byKind.flat(2).map((item, index) => {
      if (item.kind === "markdown") {
        const { content } = item;

        if (content.startsWith("* ")) {
          const listItemsRegex = /(\* .+(?:$|\n))/;
          const listItems = content.split(listItemsRegex);

          return listItems
            .filter((item) => item !== "")
            .map((thing) => {
              return {
                ...item,
                index,
                isListItem: true,
                content: thing,
              };
            });
        } else if (content.startsWith("- ")) {
          const listItemsRegex = /(- .+(?:$|\n))/;
          const listItems = content.split(listItemsRegex);

          return listItems
            .filter((item) => item !== "")
            .map((thing) => {
              return {
                ...item,
                index,
                isListItem: true,
                content: thing,
              };
            });
        } else {
          return {
            ...item,
            index,
          };
        }
      }

      return {
        ...item,
        index,
      };
    });

    let parts = byListItem.flat(3);

    if (isReview) {
      const noMarkdown = parts.filter((part) => part.kind !== "markdown");
      parts = [
        { kind: "markdown", content: "Here is your review:" },
        ...noMarkdown,
      ];
    }

    setLesson((lesson) => {
      return { ...lesson, hasBeenSplit: true, parts };
    });
    // setParts(byKind);
  }, [hasBeenSplit, isReview, markdown, showEverything, setLesson]);

  const displayedParts = showEverything ? parts : parts.slice(0, currentPart);

  return (
    <>
      {displayedParts.map((part, index) => {
        return <Part key={part} part={part} index={index} />;
      })}
    </>
  );
}

export default Reader;
