import { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

import Markdown from "@/components/types/Markdown";
import cn from "./Example.module.css";
import { hashCode, prependCSS } from "@/components/utils";
import classNames from "classnames";

const EDITOR_OPTIONS = {
  colorDecorators: true,
  fontSize: 16,
  hideCursorInOverviewRuler: true,
  lineNumbers: "off",
  minimap: {
    enabled: false,
  },
  overviewRuler: false,
  overviewRulerBorder: true,
  padding: {
    top: 20,
  },
  readOnly: true,
  renderLineHighlight: "none",
  renderOverviewRuler: false,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
  scrollBeyondLastLine: false,
  tabSize: 2,
};

function Example({ id, baseCSS, baseHTML, hiddenCSS, hiddenHTML, metaJSON }) {
  const baseID = `example-${hashCode(id)}`;
  const baseStyleRef = useRef();
  const hiddenStyleRef = useRef();
  const userOutputRef = useRef();
  const sourceHTML = baseHTML || hiddenHTML;
  const [outputWidth, setOutputWidth] = useState(100);
  const displayedHTML = sourceHTML.replace(/ data-label="[a-zA-Z ]+"/gm, "");

  // Events
  const handleResize = (event) => {
    setOutputWidth(event.target.value);
  };

  useEffect(() => {
    if (hiddenStyleRef.current) {
      hiddenStyleRef.current.innerHTML = prependCSS(hiddenCSS, baseID);
    }
  }, [hiddenCSS, baseID]);

  useEffect(() => {
    if (baseStyleRef.current && baseCSS) {
      baseStyleRef.current.innerHTML = prependCSS(baseCSS, baseID);
    }
  }, [baseCSS, baseID]);

  const mainCN = classNames({
    [cn.main]: true,
    [cn["css-only"]]: !baseHTML,
    [cn["with-meta"]]: metaJSON,
  });

  const codeCN = classNames({
    [cn.code]: true,
    [cn["code-simple"]]: true,
  });

  const htmlCN = classNames({
    "monaco-html": true,
    [cn.html]: true,
    [cn["html-only"]]: !baseCSS,
  });

  const meta = metaJSON ? JSON.parse(metaJSON) : {};

  const outputStyle = meta.resize
    ? {
        width: `${outputWidth}%`,
      }
    : {};

  return (
    <div className={mainCN}>
      <style ref={hiddenStyleRef} />
      <style ref={baseStyleRef} />

      <div className={codeCN}>
        <div className={cn.left}>
          {meta.description && (
            <div className={cn.controls} data-type="info">
              <Markdown markdown={meta.description} />
            </div>
          )}

          {baseCSS && (
            <div className={`monaco-css ${cn.css}`} data-type="css">
              <Editor
                defaultLanguage="css"
                value={baseCSS}
                options={EDITOR_OPTIONS}
                theme="vs-dark"
              />
            </div>
          )}

          {baseHTML && (
            <div className={htmlCN} data-type="html">
              <Editor
                defaultLanguage="html"
                defaultValue={displayedHTML}
                options={EDITOR_OPTIONS}
                readonly={true}
                theme="vs-dark"
              />
            </div>
          )}
        </div>

        <div className={cn.right}>
          {meta.resize && (
            <div className={`${cn.controls} ${cn.resize}`}>
              <div className={cn.info}>Resize the viewport</div>
              <input
                type="range"
                min="50"
                max="100"
                value={outputWidth}
                onChange={handleResize}
              />
            </div>
          )}

          <div className={cn.output} data-type="output" style={outputStyle}>
            <div className={cn.scroller}>
              <div
                id={baseID}
                className="example"
                ref={userOutputRef}
                dangerouslySetInnerHTML={{ __html: displayedHTML }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Example.propTypes = {
  id: PropTypes.string,
  baseCSS: PropTypes.string,
  baseHTML: PropTypes.string,
  hiddenCSS: PropTypes.string,
  hiddenHTML: PropTypes.string,
  metaJSON: PropTypes.string,
  kind: PropTypes.oneOf(["example", "exercise"]),
};

export default Example;
