import classNames from "classnames";
import PropTypes from "prop-types";

import cn from "./Viewport.module.css";
import { useEffect, useRef, useState } from "react";

function Viewport({ controls, output }) {
  const mainRef = useRef(null);
  const outputRef = useRef(null);
  const [isResizing, setResizing] = useState(false);
  const [coordinates, setCoordinates] = useState({
    x: 0,
    w: 1000,
    max: 1000,
    styleWidth: 1000,
    resizing: false,
  });

  const mainCN = classNames({
    [cn.main]: true,
  });

  const handleMouseDown = (event) => {
    const computedStyles = window.getComputedStyle(outputRef.current);

    setResizing(true);
    setCoordinates((old) => {
      return {
        ...old,
        x: event.clientX,
        w: parseInt(computedStyles.width, 10),
      };
    });
  };

  const docMouseLeave = () => {
    setResizing(false);
  };

  const docMouseUp = () => {
    setResizing(false);
  };

  useEffect(() => {
    const docMouseMove = (event) => {
      if (!isResizing) {
        return;
      }

      const { x, w, max } = coordinates;
      const dx = event.clientX - x;
      const desiredWidth = w + dx;

      if (desiredWidth < 320 || desiredWidth > max) {
        return;
      }

      setCoordinates((old) => {
        return {
          ...old,
          styleWidth: desiredWidth,
        };
      });
    };

    window.addEventListener("mousemove", docMouseMove);

    return () => {
      window.removeEventListener("mousemove", docMouseMove);
    };
  }, [coordinates, isResizing]);

  useEffect(() => {
    window.addEventListener("mouseleave", docMouseLeave);

    return () => {
      window.removeEventListener("mouseleave", docMouseLeave);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mouseup", docMouseUp);

    return () => {
      window.removeEventListener("mouseup", docMouseUp);
    };
  }, []);

  useEffect(() => {
    if (mainRef.current) {
      const computedStyles = window.getComputedStyle(mainRef.current);

      setCoordinates((old) => {
        return {
          ...old,
          w: parseInt(computedStyles.width, 10),
          max: parseInt(computedStyles.width, 10),
          styleWidth: parseInt(computedStyles.width, 10),
        };
      });
    }
  }, []);

  const style = {
    width: `${coordinates.styleWidth}px`,
  };

  return (
    <div className={mainCN} ref={mainRef}>
      <div className={cn.output} style={style} ref={outputRef}>
        <div className={cn.viewport}>{output}</div>
        <div
          className="handle"
          onMouseDown={handleMouseDown}
          onMouseUp={docMouseUp}
        >
          <div />
          <div />
        </div>
      </div>

      <div className={cn.controls}>{controls}</div>
    </div>
  );
}

Viewport.propTypes = {
  controls: PropTypes.node,
  output: PropTypes.node,
};

export default Viewport;
