import { compile, serialize, stringify } from "stylis";

export const sendAttemptResult = async (
  courseId,
  componentId,
  result,
  token,
) => {
  const csrfToken = document.querySelector("meta[name='csrf-token']");

  if (!csrfToken) {
    throw new Error("No token available.");
  }

  const options = {
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.content,
    },
    credentials: "include", // Include cookies in the request,
  };

  try {
    const response = await fetch(
      `/api/component/rate/exercise/${courseId}/${componentId}/${result}/${token}`,
      options,
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    console.error("Error fetching data: " + error.message);
    return null;
  }
};

export const detectOS = () => {
  const platform = navigator.platform;

  if (platform.indexOf("Mac") !== -1) {
    return "mac";
  }

  return "win";
};

export const removeWhitespace = (str) => str.replace(/^\s+|\s+$/g, "");

const SKIP = ["from", "to"];

const prefixRule = (element, prefix) => {
  if (
    element.type &&
    element.type === "rule" &&
    !SKIP.includes(element.value) &&
    !element.value.endsWith("%")
  ) {
    const newProps = element.props.map((prop) => `#${prefix} ${prop}`);
    return { ...element, props: newProps };
  }

  return element;
};

export const prependCSS = (inputCSS, prefix) => {
  const compiledItems = compile(inputCSS);

  if (compiledItems.length === 0) {
    return "";
  }

  const transformedItems = compiledItems.map((element) => {
    let result = element;
    result = prefixRule(element, prefix);

    const traverseTree = (element) => {
      if (
        element.children &&
        Array.isArray(element.children) &&
        element.children.length > 0
      ) {
        result.children = element.children.map((child) => {
          return prefixRule(child, prefix);
        });

        // Loop through each child element
        // for (let i = 0; i < element.children.length; i++) {
        //   // Recursively call traverseTree for each child
        //   traverseTree(result.children[i]);
        // }
      }
    };

    traverseTree(element);

    return result;
  });

  return serialize(transformedItems, stringify);
};

export async function badFetch(id) {
  const response = await fetch(
    `${import.meta.env.VITE_DEV_WEBSITE_HOST}/courses/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return response;
}

export const getChildrenComputedStyle = (parentRef) => {
  if (parentRef.current) {
    const parentElement = parentRef.current;
    const children = Array.from(parentElement.children);

    children.forEach((child) => {
      const computedStyle = window.getComputedStyle(child);
      console.log(`Computed style for ${child.nodeName}:`, computedStyle);
    });
  }
};

export const checkAnswer = (ref, validAnswer) => {
  const answerErrors = [];

  if (ref.current) {
    const parentElement = ref.current;
    const children = Array.from(parentElement.children);

    children.forEach((child) => {
      const computedStyle = window.getComputedStyle(child);
      const className = child.className;

      if (className in validAnswer) {
        const rules = validAnswer[className];

        for (const [property, value] of Object.entries(rules)) {
          if (computedStyle[property] != value) {
            answerErrors.push(`
                For ${className}, property ${property}:
                - expected ${value}
                - resulted ${computedStyle[property]}
                `);
          }
        }
      }
    });
  }

  if (answerErrors.length > 0) {
    alert(`${answerErrors.length} errors`);
  } else {
    alert("Good job!");
  }
};

// Update dynamic styles by manipulating the style tag
export const handleApplyUserStyles = (syntaxErrors, setShowSyntaxErrors) => {
  // First check if syntax is valid
  if (syntaxErrors.length > 0) {
    alert(`There are ${syntaxErrors.length} syntax errors.`);
    setShowSyntaxErrors(true);
    return false;
  }

  alert("All correct. Congrats!");
  setShowSyntaxErrors(false);
  return true;
};

export const redactCSS = (inputCSS, selector) => {
  return inputCSS.replaceAll(selector, "");
};

export const hashCode = (str) => {
  let hash = 0;
  let chr;

  if (str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};
