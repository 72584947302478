/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import { default as ReactMarkdown } from "react-markdown";
import PropTypes from "prop-types";
import { visit } from "unist-util-visit";
import remarkDirective from "remark-directive";

import Highlighter from "./Highlighter";
import Note from "@/components/elements/Note";

function customNotesPlugin() {
  return function transformer(tree) {
    const visitor = (node) => {
      if (node.name !== "note") {
        return;
      }

      const data = node.data || (node.data = {});
      data.hName = "note";
      data.hProperties = { type: node.attributes.type ?? "info" };
    };
    visit(tree, ["containerDirective"], visitor);
  };
}

const Markdown = ({ markdown, ...rest }) => {
  return (
    <ReactMarkdown
      children={markdown}
      components={{
        a({ children, ...props }) {
          let url = new URL(props.href ?? "", location.href);
          props.target = "_blank";

          if (url.origin !== location.origin) {
            props.rel = "noopener noreferrer";
          }

          return <a {...props}>{children}</a>;
        },
        code(props) {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");

          return match ? (
            <Highlighter
              {...rest}
              PreTag="div"
              children={String(children).replace(/\n$/, "")}
              language={match[1]}
            />
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        },
        note: ({ type, children }) => {
          return <Note type={type}>{children}</Note>;
        },
      }}
      remarkPlugins={[remarkDirective, customNotesPlugin]}
      {...rest}
    />
  );
};

Markdown.propTypes = {
  markdown: PropTypes.string,
};

export default Markdown;
