import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import cn from "./SimpleExample.module.css";
import { hashCode, prependCSS } from "@/components/utils";
import classNames from "classnames";

function SimpleExample({ id, hiddenHTML, hiddenCSS, metaJSON }) {
  const mainRef = useRef(null);
  const outputRef = useRef(null);
  const baseID = `simple-example-${hashCode(id)}`;
  const hiddenStyleRef = useRef();
  const [isResizing, setResizing] = useState(false);
  const [coordinates, setCoordinates] = useState({
    x: 0,
    w: 1000,
    max: 1000,
    styleWidth: 1000,
    resizing: false,
  });
  const meta = metaJSON ? JSON.parse(metaJSON) : {};

  const handleMouseDown = (event) => {
    const computedStyles = window.getComputedStyle(outputRef.current);

    setResizing(true);
    setCoordinates((old) => {
      return {
        ...old,
        x: event.clientX,
        w: parseInt(computedStyles.width, 10),
      };
    });
  };

  const docMouseLeave = () => {
    setResizing(false);
  };

  const docMouseUp = () => {
    setResizing(false);
  };

  useEffect(() => {
    if (hiddenStyleRef.current && hiddenCSS) {
      hiddenStyleRef.current.innerHTML = prependCSS(hiddenCSS, baseID);
    }
  }, [hiddenCSS, baseID]);

  useEffect(() => {
    const docMouseMove = (event) => {
      if (!isResizing) {
        return;
      }

      const { x, w, max } = coordinates;
      const dx = event.clientX - x;
      const desiredWidth = w + dx;

      if (desiredWidth < 320 || desiredWidth > max) {
        return;
      }

      setCoordinates((old) => {
        return {
          ...old,
          styleWidth: desiredWidth,
        };
      });
    };

    window.addEventListener("mousemove", docMouseMove);

    return () => {
      window.removeEventListener("mousemove", docMouseMove);
    };
  }, [coordinates, isResizing]);

  useEffect(() => {
    window.addEventListener("mouseleave", docMouseLeave);

    return () => {
      window.removeEventListener("mouseleave", docMouseLeave);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mouseup", docMouseUp);

    return () => {
      window.removeEventListener("mouseup", docMouseUp);
    };
  }, []);

  useEffect(() => {
    if (mainRef.current) {
      const computedStyles = window.getComputedStyle(mainRef.current);

      setCoordinates((old) => {
        return {
          ...old,
          w: parseInt(computedStyles.width, 10),
          max: parseInt(computedStyles.width, 10),
          styleWidth: parseInt(computedStyles.width, 10),
        };
      });
    }
  }, []);

  const mainCN = classNames({
    [cn.main]: true,
    [cn.resize]: meta.resize,
  });

  const outputStyle = meta.resize
    ? {
        width: `${coordinates.styleWidth}px`,
      }
    : {};

  return (
    <div className={mainCN} ref={mainRef}>
      <style ref={hiddenStyleRef} />

      <div className={cn.code}>
        <div
          className={cn.output}
          data-type="example"
          style={outputStyle}
          ref={outputRef}
        >
          <div
            id={baseID}
            className="example"
            dangerouslySetInnerHTML={{ __html: hiddenHTML }}
          />
          {meta.resize && (
            <div
              className="handle"
              onMouseDown={handleMouseDown}
              onMouseUp={docMouseUp}
            >
              <div />
              <div />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

SimpleExample.propTypes = {
  id: PropTypes.string,
  hiddenHTML: PropTypes.string,
  hiddenCSS: PropTypes.string,
  metaJSON: PropTypes.string,
};

export default SimpleExample;
