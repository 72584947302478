import classNames from "classnames";
import PropTypes from "prop-types";

import cn from "./Note.module.css";
import SVG from "@/components/elements/SvgIcon";

const TYPE_TO_ICON = {
  info: "info",
  success: "check",
  warning: "exclamation",
  danger: "ban",
  key: "exclamation",
};

const TYPE_TO_LABEL = {
  info: "Note",
  success: "Note",
  warning: "Important",
  danger: "Warning",
  key: "Key Info",
};

const Note = ({ children, type }) => {
  const mainCN = classNames({
    [cn.note]: true,
    [cn[type]]: type,
  });

  let icon = "info";

  if (type in TYPE_TO_ICON) {
    icon = TYPE_TO_ICON[type];
  }

  let attr = {
    "data-label": TYPE_TO_LABEL[type],
  };

  return (
    <div className={mainCN} {...attr}>
      <div className={cn.svg}>
        <SVG icon={icon} />
      </div>
      <div className={cn.body}>{children}</div>
    </div>
  );
};

Note.propTypes = {
  children: PropTypes.element,
  type: PropTypes.string,
};

export default Note;
