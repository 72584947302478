import PropTypes from "prop-types";
import { useContext, useState } from "react";

import { LessonContext } from "@/components/Lesson";
import Markdown from "@/components/types/Markdown";

import classNames from "classnames";
import { ReviewContext } from "../Review";
import { sendAttemptResult } from "../utils";
import cn from "./Freetext.module.css";

function Freetext({ id, index, metaJSON }) {
  const {
    continueLesson = () => {},
    currentPart,
    isLoading: lessonIsLoading,
    setLoading: setLessonLoading,
  } = useContext(LessonContext);
  const {
    continueReview = () => {},
    currentIndex,
    isLoading: reviewIsLoading,
    setLoading: setReviewLoading,
  } = useContext(ReviewContext);

  const showContinue = index === currentPart - 1 || index === currentIndex;

  const meta = JSON.parse(metaJSON);
  const { description, regex, placeholder = "" } = meta;
  const regValue = new RegExp(regex);

  const [inputValue, setInputValue] = useState(placeholder);
  const [attempts, setAttempts] = useState([]);
  const [hasError, setError] = useState(false);
  const [isSuccessful, setSuccess] = useState(false);

  const somethingIsLoading = lessonIsLoading || reviewIsLoading;

  const handleInputChange = (event) => {
    setError(false);
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (inputValue === "") {
      return;
    }

    setLessonLoading(true);
    setReviewLoading(true);

    setAttempts((old) => {
      if (old.includes(inputValue)) {
        return old;
      }

      return [...old, inputValue];
    });

    if (regValue.test(inputValue)) {
      await handleAttempt("success");
      setSuccess(true);
    } else {
      await handleAttempt("failure");
      setError(true);
    }

    setLessonLoading(false);
    setReviewLoading(false);

    setTimeout(() => {
      window.scrollTo({
        top: window.document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 50);
  };

  const handleAttempt = async (result) => {
    if (window.CSSMasterClass) {
      await sendAttemptResult(
        window.CSSMasterClass.courseId,
        id,
        result,
        window.CSSMasterClass.token,
      );
    }
  };

  const handleContinue = () => {
    continueLesson();
    continueReview();
  };

  const handleSkip = async () => {
    if (window.confirm("Are you sure you want to skip this exercise?")) {
      await handleAttempt("skip");
      continueLesson();
      continueReview();
    }
  };

  const mainCN = classNames({
    [cn.main]: true,
  });

  const formCN = classNames({
    "mini-form": true,
  });

  return (
    <div className={mainCN}>
      <div className={cn.instructions} data-type="exercise">
        <Markdown markdown={description} />

        <form
          className={formCN}
          disabled={somethingIsLoading}
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            disabled={isSuccessful || somethingIsLoading}
            className="form-control is-tight"
          />

          <button className="button is-outlined" disabled={somethingIsLoading}>
            <span className="button-label">Submit Answer</span>
          </button>
        </form>

        {hasError && (
          <div className="notification is-alert">
            <p>Sorry, this is incorrect. Please try again.</p>
          </div>
        )}

        {isSuccessful && (
          <div className="notification is-bold">
            <p>That is correct. Well done!</p>
          </div>
        )}
      </div>

      {!isSuccessful && attempts.length > 3 && (
        <div className={cn.skip}>
          Unable to find the correct solution?
          <button
            className="link"
            onClick={handleSkip}
            disabled={somethingIsLoading}
          >
            Skip this exercise
          </button>
        </div>
      )}

      {isSuccessful && showContinue && (
        <div className={cn.continue}>
          <button
            className="button is-outlined"
            onClick={handleContinue}
            disabled={somethingIsLoading}
          >
            <span className="button-label">Continue</span>
          </button>
        </div>
      )}
    </div>
  );
}

Freetext.propTypes = {
  id: PropTypes.string,
  metaJSON: PropTypes.string,
  index: PropTypes.number,
};

export default Freetext;
