const LIST_OF_SVGS = {
  ban: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M18 10c0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8ZM4.875 5.969A6.548 6.548 0 0 0 3.5 10c0 3.594 2.906 6.5 6.5 6.5 1.5 0 2.906-.5 4.031-1.375L4.875 5.969ZM16.5 10c0-3.563-2.938-6.5-6.5-6.5a6.597 6.597 0 0 0-4.063 1.406l9.157 9.157A6.597 6.597 0 0 0 16.5 10Z" />
    </svg>
  ),
  check: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M16.688 5.313a.964.964 0 0 1 0 1.406l-8 8a.964.964 0 0 1-1.407 0l-4-4a.964.964 0 0 1 0-1.406.964.964 0 0 1 1.406 0L8 12.592l7.281-7.28a.964.964 0 0 1 1.406 0Z" />
    </svg>
  ),
  circle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M18 10c0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8Zm-8-6.5c-3.594 0-6.5 2.938-6.5 6.5 0 3.594 2.906 6.5 6.5 6.5 3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5Z" />
    </svg>
  ),
  "circle-check": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 18c-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8Zm3.531-9.469H13.5c.313-.281.313-.75 0-1.062a.736.736 0 0 0-1.031 0L9 10.969 7.531 9.5c-.312-.313-.781-.313-1.062 0a.684.684 0 0 0 0 1.031l2 2c.281.313.75.313 1.062 0l4-4Z" />
    </svg>
  ),
  "circle-dot": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 18c-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8Zm0-5a3 3 0 1 0-.002-6.002A3 3 0 0 0 10 13Z" />
    </svg>
  ),
  "circle-xmark": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 18c-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8ZM7.469 7.469c-.313.312-.313.781 0 1.062L8.937 10 7.47 11.469c-.313.312-.313.781 0 1.062a.684.684 0 0 0 1.031 0l1.469-1.469 1.469 1.47c.312.312.78.312 1.062 0 .313-.282.313-.75 0-1.063L11.031 10 12.5 8.531c.313-.281.313-.75 0-1.062-.281-.282-.75-.282-1.063 0L9.97 8.937 8.5 7.47a.736.736 0 0 0-1.031 0Z" />
    </svg>
  ),
  exclamation: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M11 4v8c0 .563-.469 1-1 1-.563 0-1-.438-1-1V4c0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-1 13c-.719 0-1.25-.531-1.25-1.25 0-.688.531-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25 0 .719-.563 1.25-1.25 1.25Z" />
    </svg>
  ),
  info: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M11.5 4.5A1.5 1.5 0 0 1 10 6a1.48 1.48 0 0 1-1.5-1.5A1.5 1.5 0 0 1 10 3c.813 0 1.5.688 1.5 1.5ZM7 9c0-.531.438-1 1-1h2c.531 0 1 .469 1 1v7h1c.531 0 1 .469 1 1 0 .563-.469 1-1 1H8c-.563 0-1-.438-1-1 0-.531.438-1 1-1h1v-6H8c-.563 0-1-.438-1-1Z" />
    </svg>
  ),
  lightbulb: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="m2.156 2.125 1.5 1c.344.25.438.719.219 1.063-.25.343-.719.437-1.063.187l-1.5-1a.717.717 0 0 1-.187-1.031.75.75 0 0 1 1.031-.219Zm16.5 1.25-1.5 1a.717.717 0 0 1-1.031-.188c-.25-.343-.156-.812.188-1.062l1.5-1a.795.795 0 0 1 1.062.219.75.75 0 0 1-.219 1.031ZM.75 7h2a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-2A.722.722 0 0 1 0 7.75.74.74 0 0 1 .75 7Zm16.5 0h2a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-2a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75ZM3.656 12.375l-1.5 1a.717.717 0 0 1-1.031-.188c-.25-.343-.156-.812.188-1.062l1.5-1a.795.795 0 0 1 1.062.219.75.75 0 0 1-.219 1.031Zm13.5-1.219 1.5 1a.75.75 0 0 1 .219 1.031.795.795 0 0 1-1.063.22l-1.5-1c-.343-.25-.437-.72-.187-1.063a.717.717 0 0 1 1.031-.188Zm-3.125.156c-.625.844-1.25 1.72-1.531 2.688h-5c-.313-.969-.938-1.844-1.563-2.688l-.468-.656A5.469 5.469 0 0 1 4.5 7.5C4.5 4.469 6.938 2 10 2c3.031 0 5.5 2.469 5.5 5.5a5.49 5.49 0 0 1-1 3.156l-.469.656ZM12.5 15.5c0 1.406-1.125 2.5-2.5 2.5a2.468 2.468 0 0 1-2.5-2.5V15h5v.5ZM10 5c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5-1.938 0-3.5 1.594-3.5 3.5 0 .281.219.5.5.5.25 0 .5-.219.5-.5C7.5 6.125 8.594 5 10 5Z" />
    </svg>
  ),
  "triangle-exclamation": (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M17.813 15.031c.5.875-.125 1.969-1.157 1.969H3.313a1.34 1.34 0 0 1-1.188-1.969L8.813 3.656A1.384 1.384 0 0 1 10 3c.438 0 .875.219 1.156.656l6.656 11.375ZM3.624 15.5h12.719L9.969 4.656 3.625 15.5ZM10 12.562c.531 0 .969.438.969.97A.974.974 0 0 1 10 14.5a.98.98 0 0 1-1-.969.98.98 0 0 1 1-.969ZM9.25 7.75A.74.74 0 0 1 10 7a.76.76 0 0 1 .75.75v3a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75v-3Z" />
    </svg>
  ),
  xmark: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M14.688 6.719 11.405 10l3.281 3.313a.964.964 0 0 1 0 1.406.964.964 0 0 1-1.406 0L10 11.438l-3.313 3.28a.964.964 0 0 1-1.406 0 .964.964 0 0 1 0-1.405L8.563 10 5.28 6.719a.964.964 0 0 1 0-1.407.964.964 0 0 1 1.407 0L10 8.595l3.281-3.281a.964.964 0 0 1 1.406 0 .964.964 0 0 1 0 1.406Z" />
    </svg>
  ),
};

const SVG = ({ icon }) => LIST_OF_SVGS[icon];

export default SVG;
