import { createContext, useState } from "react";

import Part from "@/components/Part";

export const ReviewContext = createContext({
  currentIndex: -1,
  exerciseIds: [],
  exercises: [],
  addExercise: () => {},
  isLoading: false,
  setLoading: () => {},
  hasStarted: false,
  setStarted: () => {},
  isComplete: false,
});

function Review() {
  const initialReview = {
    currentIndex: -1,
    setIndex: (index) => {
      setReview((review) => {
        return { ...review, currentIndex: index };
      });
    },
    exerciseIds: window.CSSMasterClass.exercises,
    exercises: [],
    addExercise: (exercise) => {
      setReview((review) => {
        return { ...review, exercises: [...review.exercises, exercise] };
      });
    },
    isLoading: false,
    setLoading: (bool) => {
      setReview((review) => {
        return { ...review, isLoading: bool };
      });
    },
    hasStarted: false,
    setStarted: (bool) => {
      setReview((review) => {
        return { ...review, hasStarted: bool };
      });
    },
    isComplete: false,
  };

  const [review, setReview] = useState(initialReview);

  const fetchComponent = async (
    courseId,
    componentKind,
    componentId,
    token,
  ) => {
    const csrfToken = document.querySelector("meta[name='csrf-token']");

    if (!csrfToken) {
      throw new Error("No token available.");
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      credentials: "include", // Include cookies in the request,
    };

    try {
      const response = await fetch(
        `/api/component/get/${componentKind}/${courseId}/${componentId}/${token}`,
        options,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data: " + error.message);
      return null;
    }
  };

  const continueReview = async () => {
    if (review.currentIndex === review.exerciseIds.length - 1) {
      setReview((review) => {
        return { ...review, isComplete: true };
      });

      setTimeout(() => {
        window.scrollTo({
          top: window.document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }, 50);

      return;
    }

    const nextIndex = review.currentIndex + 1;
    review.setLoading(true);

    try {
      const component = await fetchComponent(
        window.CSSMasterClass.courseId,
        "exercise",
        review.exerciseIds[nextIndex],
        window.CSSMasterClass.token,
      );

      let kind = "exercise";

      if (component.files["quiz.json"]) {
        kind = "quiz";
      }

      const exercise = {
        content: `@${kind}=${component.id}`,
        files: component.files,
        id: component.id,
        index: nextIndex,
        kind,
      };

      if (nextIndex > -1) {
        review.setStarted(true);
      }

      review.addExercise(exercise);
      review.setIndex(nextIndex);
      review.setLoading(false);
    } catch (error) {
      // Handling any errors that occurred during the asynchronous operation
      console.error("Error:", error.message);
      review.setLoading(false);
    }

    setTimeout(() => {
      window.scrollTo({
        top: window.document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 50);
  };
  review.continueReview = continueReview;

  const handleEndReview = async () => {
    window.location.href = `/courses`;
  };

  return (
    <ReviewContext.Provider value={review}>
      {review.hasStarted || (
        <div className="single-lesson-start">
          <button
            className="button is-primary"
            disabled={review.isLoading}
            onClick={continueReview}
          >
            <span className="button-label">Start</span>
          </button>
        </div>
      )}

      <div className="sections">
        {review.exercises.map((exercise, n) => (
          <Part key={exercise} part={exercise} index={n} />
        ))}
      </div>

      {review.isComplete && (
        <div className="single-review-end">
          <p>Congratulations! You&lsquo;ve finished your review.</p>

          <button
            disabled={review.isLoading}
            onClick={handleEndReview}
            className="button is-primary"
          >
            <span className="button-label">Complete Review</span>
          </button>
        </div>
      )}
    </ReviewContext.Provider>
  );
}

export default Review;
