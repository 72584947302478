import PropTypes from "prop-types";

import Markdown from "./types/Markdown";
import Dispatcher from "./types/Dispatcher";
import Quiz from "./types/Quiz";
import classNames from "classnames";

function Part({ part, index }) {
  const { id, kind, content, files, isListItem } = part;

  const isMarkdown = kind === "markdown";
  const isHTML = kind === "html";
  const isExample = kind === "example";
  const isExercise = kind === "exercise";
  const isQuiz = kind === "quiz";

  let body = null;

  // Build text body
  if (isMarkdown) {
    body = <Markdown markdown={content} />;
  } else if (isHTML) {
    body = content;
  } else if (isExample) {
    body = <Dispatcher kind="example" part={part} index={index} />;
  } else if (isExercise) {
    body = <Dispatcher kind="exercise" part={part} index={index} />;
  } else if (isQuiz) {
    body = <Quiz id={id} index={index} quizJSON={files["quiz.json"]} />;
  }

  let preciseKind = kind;

  // Can be:
  // - a simple example (no HTML, no CSS)
  // - an example with controls (has controls.json)
  // - a simple exercise (only free text)

  if (isExample) {
    if (
      files["controls.json"] ||
      files["advanced.json"] ||
      files["sliders.json"]
    ) {
      preciseKind = "example-with-controls";
    } else if (!files["base.css"] && files["hidden.html"]) {
      if ("large.json" in files) {
        preciseKind = "simple-example is-large";
      } else {
        preciseKind = "simple-example";
      }
    }
  }

  if (isExercise) {
    if (!files["base.html"]) {
      preciseKind = "simple-exercise";
    }
  }

  const sectionCN = classNames({
    [`section is-${preciseKind}`]: true,
    [`is-list-item`]: isListItem,
  });

  if (isHTML) {
    return (
      <article key="main-section" className={sectionCN}>
        <div
          className="section-body"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </article>
    );
  }

  return (
    <article key="main-section" className={sectionCN}>
      <div className="section-body">{body}</div>
    </article>
  );
}

Part.propTypes = {
  part: PropTypes.object,
  index: PropTypes.number,
};

export default Part;
