import { useState } from "react";

import Viewport from "@/components/blocks/Viewport";

import cn from "./ColumnSystem.module.css";

const ColumnSystem = () => {
  const [count, setCount] = useState(6);

  let columns = [];

  for (let index = 0; index < count; index++) {
    columns.push(<div className={cn.column}>{index + 1}</div>);
  }

  const handleChange = (n) => {
    setCount(Math.max(1, Math.min(12, count + n)));
  };

  const controls = (
    <div className={cn.controls}>
      <button className="button is-outlined" onClick={() => handleChange(1)}>
        <span className="button-label">Add column</span>
      </button>
      <button className="button is-outlined" onClick={() => handleChange(-1)}>
        <span className="button-label">Remove</span>
      </button>
    </div>
  );

  const output = <div className={cn.columns}>{columns}</div>;

  return <Viewport controls={controls} output={output} />;
};

export default ColumnSystem;
