import { createContext, useEffect, useState } from "react";
import Reader from "./Reader";
import Feedback from "./types/Feedback";

export const LessonContext = createContext({
  currentPart: 1,
  parts: [],
  isComplete: false,
  isLoading: false,
  hasStarted: false,
  hasBeenSplit: false,
  showEverything: false,
  content: {},
  os: null,
  setCurrentPart: () => {},
  setLesson: () => {},
  setParts: () => {},
  setLoading: () => {},
  continueLesson: () => {},
});

function Lesson() {
  const initialLesson = {
    currentPart: 1,
    parts: [],
    isComplete: false,
    isLoading: false,
    hasStarted: false,
    hasBeenSplit: false,
    showEverything: false,
    content: {},
    os: null,
    setCurrentPart: (current) => {
      setLesson((lesson) => {
        return { ...lesson, currentPart: current };
      });
    },
    setLoading: (bool) => {
      setLesson((lesson) => {
        return { ...lesson, isLoading: bool };
      });
    },
    setParts: (parts) => {
      setLesson((lesson) => {
        return { ...lesson, parts: parts };
      });
    },
  };

  const [lesson, setLesson] = useState(initialLesson);

  const fetchLesson = async (courseId, token) => {
    lesson.setLoading(true);

    const csrfToken = document.querySelector("meta[name='csrf-token']");

    if (!csrfToken) {
      throw new Error("No token available.");
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      credentials: "include", // Include cookies in the request,
    };

    try {
      const response = await fetch(
        `/api/course/content/${courseId}/lesson/${token}`,
        options,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setLesson((lesson) => {
        return { ...lesson, content: data, hasStarted: true };
      });
    } catch (error) {
      console.error("Error fetching data: " + error.message);
    } finally {
      lesson.setLoading(false);
    }
  };

  const fetchComponent = async (
    courseId,
    componentKind,
    componentId,
    token,
  ) => {
    const csrfToken = document.querySelector("meta[name='csrf-token']");

    if (!csrfToken) {
      throw new Error("No token available.");
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      credentials: "include", // Include cookies in the request,
    };

    try {
      const response = await fetch(
        `/api/component/get/${componentKind}/${courseId}/${componentId}/${token}`,
        options,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data: " + error.message);
      return null;
    }
  };

  const completeLesson = async (courseId, token) => {
    setLesson((lesson) => {
      return { ...lesson, isLoading: true };
    });

    const csrfToken = document.querySelector("meta[name='csrf-token']");

    if (!csrfToken) {
      throw new Error("No token available.");
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.content,
      },
      credentials: "include", // Include cookies in the request,
      body: JSON.stringify({
        token,
      }),
    };

    try {
      const response = await fetch(
        `/api/course/complete/${courseId}/lesson/${token}`,
        options,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      window.location.href = `/course/${courseId}`;
    } catch (error) {
      console.error("Error fetching data: " + error.message);
    } finally {
      setLesson((lesson) => {
        return { ...lesson, isLoading: false };
      });
    }
  };

  const startLesson = async () => {
    if (lesson.hasStarted) {
      return;
    }

    return await fetchLesson(
      window.CSSMasterClass.courseId,
      window.CSSMasterClass.token,
    );
  };

  const continueLesson = async () => {
    // We've shown all parts
    if (lesson.isComplete || lesson.currentPart === lesson.parts.length) {
      return;
    }

    // First, let's see what the next part is
    // (markdown, example, or exercise)
    const nextPart = lesson.parts[lesson.currentPart]; // currentPart is 1-based

    if (nextPart.kind === "example" || nextPart.kind === "exercise") {
      const result = nextPart.content.split("=");
      const id = result[result.length - 1];

      lesson.setLoading(true);

      try {
        const component = await fetchComponent(
          window.CSSMasterClass.courseId,
          nextPart.kind,
          id,
          window.CSSMasterClass.token,
        );

        const updatedParts = lesson.parts.map((item) => {
          if (item.index === nextPart.index) {
            return {
              ...nextPart,
              id: component.id,
              files: component.files,
            };
          }

          return item;
        });

        setLesson((lesson) => {
          return {
            ...lesson,
            isLoading: false,
            currentPart: lesson.currentPart + 1,
            parts: updatedParts,
          };
        });
      } catch (error) {
        // Handling any errors that occurred during the asynchronous operation
        console.error("Error:", error.message);
        lesson.setLoading(false);
      }
    } else {
      // It's probably markdown
      // Just go to the next part
      lesson.setCurrentPart(lesson.currentPart + 1);
    }

    setTimeout(() => {
      window.scrollTo({
        top: window.document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 50);
  };

  const endLesson = async () => {
    if (!lesson.isComplete) {
      return;
    }

    return await completeLesson(
      window.CSSMasterClass.courseId,
      window.CSSMasterClass.token,
    );
  };

  const latestPart = lesson.parts[lesson.currentPart - 1]; // currentPart is 1-based
  const showContinue =
    lesson.hasStarted &&
    ["markdown", "html", "example"].includes(latestPart?.kind) &&
    !lesson.isComplete;

  lesson.continueLesson = continueLesson;
  lesson.setLesson = setLesson;

  useEffect(() => {
    if (
      lesson.hasStarted &&
      lesson.currentPart === lesson.parts.length &&
      !lesson.isComplete
    ) {
      setLesson((lesson) => {
        return { ...lesson, isComplete: true };
      });
    }
  }, [lesson]);

  return (
    <LessonContext.Provider value={lesson}>
      {lesson.hasStarted || (
        <div className="single-lesson-start">
          <button
            disabled={lesson.isLoading}
            onClick={startLesson}
            className="button is-primary"
          >
            <span className="button-label">Start</span>
          </button>
        </div>
      )}

      {lesson.hasStarted && (
        <div className="sections">
          <Reader content={lesson.content} />
        </div>
      )}

      {showContinue && (
        <div key="continue" className="section-continue">
          <button
            onClick={continueLesson}
            className="button is-outlined"
            disabled={lesson.isLoading}
          >
            <span className="button-label">
              {lesson.isLoading ? "Loading…" : "Continue"}
            </span>
          </button>
        </div>
      )}

      {lesson.isComplete && (
        <div className="single-lesson-feedback">
          <Feedback />
        </div>
      )}

      {lesson.isComplete && (
        <div className="single-lesson-end">
          <button
            disabled={lesson.isLoading}
            onClick={endLesson}
            className="button is-primary"
          >
            <span className="button-label">Complete Lesson</span>
          </button>
        </div>
      )}
    </LessonContext.Provider>
  );
}

export default Lesson;
