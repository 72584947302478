import PropTypes from "prop-types";

import Example from "@/components/blocks/Example";
import ExampleWithControls from "@/components/blocks/ExampleWithControls";
import Code from "@/components/blocks/Code";
import SimpleExample from "@/components/blocks/SimpleExample";
import Freetext from "@/components/blocks/Freetext";
import Quiz from "@/components/types/Quiz";

import { ColumnSystem } from "@/components/custom";
import React from "react";

const Components = {
  columnSystem: ColumnSystem,
};

function Dispatcher({ part, index }) {
  const { id, kind, files } = part;

  if (kind === "example") {
    const baseHTML = files["base.html"];
    const baseCSS = files["base.css"];
    const advancedJSON = files["advanced.json"];
    const controlsJSON = files["controls.json"];
    const slidersJSON = files["sliders.json"];
    const metaJSON = files["meta.json"];
    const hiddenHTML = files["hidden.html"];
    const hiddenCSS = files["hidden.css"];

    const meta = metaJSON ? JSON.parse(metaJSON) : {};

    if (meta.custom) {
      if (typeof Components[meta.custom] !== "undefined") {
        return React.createElement(Components[meta.custom], {
          key: meta.custom,
        });
      }
    }

    if (controlsJSON || advancedJSON || slidersJSON) {
      return (
        <ExampleWithControls
          id={id}
          index={index}
          controlsJSON={controlsJSON}
          advancedJSON={advancedJSON}
          slidersJSON={slidersJSON}
          metaJSON={metaJSON}
          baseCSS={baseCSS}
          baseHTML={baseHTML}
          hiddenCSS={hiddenCSS}
          hiddenHTML={hiddenHTML}
        />
      );
    }

    if (!baseCSS && hiddenHTML) {
      return (
        <SimpleExample
          id={id}
          index={index}
          hiddenHTML={hiddenHTML}
          hiddenCSS={hiddenCSS}
          metaJSON={metaJSON}
        />
      );
    }

    return (
      <Example
        id={id}
        index={index}
        baseCSS={baseCSS}
        baseHTML={baseHTML}
        hiddenCSS={hiddenCSS}
        hiddenHTML={hiddenHTML}
        metaJSON={metaJSON}
      />
    );
  }

  if (kind === "exercise") {
    const authorCSS = files["author.css"];
    const authorJSON = files["author.json"];
    const baseCSS = files["base.css"];
    const baseHTML = files["base.html"];
    const hiddenCSS = files["hidden.css"];
    const metaJSON = files["meta.json"];
    const quizJSON = files["quiz.json"];
    const textJSON = files["text.json"];

    if (quizJSON) {
      return <Quiz id={id} index={index} quizJSON={quizJSON} />;
    }

    if (textJSON) {
      return <Freetext id={id} index={index} metaJSON={textJSON} />;
    }

    return (
      <Code
        id={id}
        index={index}
        metaJSON={metaJSON}
        authorCSS={authorCSS}
        authorJSON={authorJSON}
        baseCSS={baseCSS}
        baseHTML={baseHTML}
        hiddenCSS={hiddenCSS}
      />
    );
  }

  return <></>;
}

Dispatcher.propTypes = {
  part: PropTypes.object,
  index: PropTypes.number,
};

export default Dispatcher;
