import PropTypes from "prop-types";

function Output({ baseID, outputHTML }) {
  return (
    <div
      id={baseID}
      className="example"
      dangerouslySetInnerHTML={{ __html: outputHTML }}
    />
  );
}

Output.propTypes = {
  baseID: PropTypes.string,
  outputHTML: PropTypes.string,
};

export default Output;
