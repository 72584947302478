import Editor from "@monaco-editor/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { default as ReactMarkdown } from "react-markdown";
import { compile, serialize, stringify } from "stylis";

import cn from "./Code.module.css";

import { LessonContext } from "@/components/Lesson";
import Markdown from "@/components/types/Markdown";
import { hashCode } from "@/components/utils";

import SVG from "@/components/elements/SvgIcon";
import { ReviewContext } from "../Review";
import { sendAttemptResult } from "../utils";

const EDITOR_OPTIONS = {
  colorDecorators: false,
  fontSize: 16,
  hideCursorInOverviewRuler: true,
  lineNumbers: "off",
  minimap: {
    enabled: false,
  },
  overviewRuler: false,
  overviewRulerBorder: true,
  padding: {
    top: 20,
  },
  quickSuggestions: false,
  renderLineHighlight: "none",
  renderOverviewRuler: false,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
  scrollBeyondLastLine: false,
  tabSize: 2,
};

function Code({
  id,
  index,
  metaJSON,
  authorCSS,
  authorJSON,
  baseCSS,
  baseHTML,
  hiddenCSS,
}) {
  const {
    continueLesson = () => {},
    currentPart,
    isLoading: lessonIsLoading,
    setLoading: setLessonLoading,
  } = useContext(LessonContext);
  const {
    continueReview = () => {},
    currentIndex,
    isLoading: reviewIsLoading,
    setLoading: setReviewLoading,
  } = useContext(ReviewContext);

  const userID = `exercise-${hashCode(id)}`;
  // Appending userID to make sure they're different
  const authorID = `author-${userID}`;

  const editorRef = useRef(null);
  const hiddenUserStyleRef = useRef();
  const hiddenAuthorStyleRef = useRef();
  const userStyleRef = useRef();
  const authorStyleRef = useRef();
  const userOutputRef = useRef();
  const authorOutputRef = useRef();
  const meta = metaJSON ? JSON.parse(metaJSON) : {};

  const [userErrors, setUserErrors] = useState([]);
  const [authorAnswer, setAuthorAnswer] = useState({});
  const [viewAuthorAnswer, setViewAuthorAnswer] = useState(false);
  const [finalUserCSS, setFinalUserCSS] = useState("");
  const [isSuccessful, setSuccessful] = useState(false);
  const [outputHTML, setOutputHTML] = useState(baseHTML);

  const somethingIsLoading = lessonIsLoading || reviewIsLoading;

  function forceUpdate() {
    setOutputHTML((old) => {
      return `${old} `;
    });
  }

  // LISTENERS
  const handleEditorBeforeMount = (monaco) => {
    monaco.languages.css.cssDefaults.setDiagnosticsOptions({
      lint: {
        emptyRules: "ignore", // Options: "ignore", "warning", "error"
      },
    });
  };

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  const handleEditorChange = (value) => {
    if (userStyleRef.current) {
      userStyleRef.current.innerHTML = prependCSS(value, userID);
    }

    if (meta.force) {
      forceUpdate();
    }
  };

  const handleEditorValidation = (markers) => {
    const newErrors = [];
    markers.forEach((marker) => newErrors.push(marker.message));
  };

  // HANDLERS
  // Triggered when the user has submitted a valid answer
  const handleSuccess = async () => {
    setSuccessful(true);
    setFinalUserCSS(editorRef.current.getValue());
  };

  const handleAttempt = async (result) => {
    if (window.CSSMasterClass) {
      await sendAttemptResult(
        window.CSSMasterClass.courseId,
        id,
        result,
        window.CSSMasterClass.token,
      );
    }
  };

  // Triggered when they have a valid answer and click "Continue"
  const handleContinue = () => {
    continueLesson();
    continueReview();
  };

  const handleSkip = async () => {
    if (window.confirm("Are you sure you want to skip this exercise?")) {
      await handleAttempt("skip");
      continueLesson();
      continueReview();
    }
  };

  const handleCheckUserCode = async () => {
    setLessonLoading(true);
    setReviewLoading(true);
    const errors = [];

    if (userOutputRef.current) {
      const parentElement = userOutputRef.current;
      // const children = Array.from(parentElement.children);

      const traverseTree = (element) => {
        const computedStyle = window.getComputedStyle(element);
        const className = element.className;

        if (className in authorAnswer) {
          const rules = authorAnswer[className];

          for (const [property, value] of Object.entries(rules)) {
            if (computedStyle[property] != value) {
              errors.push(`the \`.${className}\` ${property} is incorrect`);
            }
          }
        }

        if (element.children.length > 0) {
          // Loop through each child element
          for (let i = 0; i < element.children.length; i++) {
            // Recursively call traverseTree for each child
            traverseTree(element.children[i]);
          }
        }
      };

      traverseTree(parentElement);
    }

    if (errors.length > 0) {
      await handleAttempt("failure");

      if (errors.length === 1) {
        alert(`There is ${errors.length} styling error.`);
      } else {
        alert(`There are ${errors.length} styling errors.`);
      }
    } else {
      await handleAttempt("success");

      alert("Congrats! There are no errors");
      handleSuccess();
    }

    setUserErrors(errors);
    setLessonLoading(false);
    setReviewLoading(false);
  };

  const handleViewAuthorAnswer = () => {
    if (viewAuthorAnswer) {
      editorRef.current.setValue(finalUserCSS);
      setViewAuthorAnswer(false);
    } else {
      editorRef.current.setValue(authorCSS);
      setViewAuthorAnswer(true);
    }
  };

  // UTILITIES
  // Get the validCSS and prepend the USER_ID selector to all classes
  const prependCSS = (inputCSS, prefix) => {
    const compiledItems = compile(inputCSS);

    if (compiledItems.length === 0) {
      return "";
    }

    const transformedItems = compiledItems.map((element) => {
      if (element.type === "rule") {
        const newProps = element.props.map((prop) => `#${prefix} ${prop}`);
        return { ...element, props: newProps };
      }

      return element;
    });

    return serialize(transformedItems, stringify);
  };

  // KEYBOARD
  useHotkeys("mod+enter", () => handleCheckUserCode());

  // EFFECTS
  useEffect(() => {
    if (authorStyleRef.current) {
      authorStyleRef.current.innerHTML = prependCSS(authorCSS, authorID);
    }
  }, [authorCSS, authorID]);

  useEffect(() => {
    if (authorOutputRef.current) {
      const parentElement = authorOutputRef.current;
      // const children = Array.from(parentElement.children);
      const authorAnswer = {};
      const authorJSONObj = JSON.parse(authorJSON);

      const traverseTree = (element) => {
        const className = element.className;

        // If "title" is in authorJSON,
        // then we have to parse that class
        if (className in authorJSONObj) {
          const computedStyle = window.getComputedStyle(element);
          authorAnswer[className] = {};

          authorJSONObj[className].forEach((property) => {
            authorAnswer[className][property] = computedStyle[property];
          });
        }

        if (element.children.length > 0) {
          // Loop through each child element
          for (let i = 0; i < element.children.length; i++) {
            // Recursively call traverseTree for each child
            traverseTree(element.children[i]);
          }
        }
      };

      traverseTree(parentElement);

      setAuthorAnswer(authorAnswer);
    }
  }, [authorCSS, authorJSON]);

  useEffect(() => {
    if (hiddenCSS && hiddenAuthorStyleRef.current) {
      hiddenAuthorStyleRef.current.innerHTML = prependCSS(hiddenCSS, authorID);
      hiddenUserStyleRef.current.innerHTML = prependCSS(hiddenCSS, userID);
    }
  }, [hiddenCSS, authorID, userID]);

  useEffect(() => {
    if (userStyleRef.current) {
      userStyleRef.current.innerHTML = prependCSS(baseCSS, userID);
    }
  }, [baseCSS, userID]);

  // STYLES
  const editorCN = classNames({
    ["monaco-css"]: true,
    [cn.editor]: true,
    [cn["show-author"]]: viewAuthorAnswer,
  });

  const showContinue = index === currentPart - 1 || index === currentIndex;

  const toggleCN = classNames({
    ["toggle"]: true,
    ["toggle-checked"]: viewAuthorAnswer,
  });

  return (
    <div className={cn.main}>
      <style ref={hiddenUserStyleRef} />
      <style ref={userStyleRef} />
      <style ref={hiddenAuthorStyleRef} />
      <style ref={authorStyleRef} />

      <div className={cn.code}>
        <div className={cn.left}>
          {metaJSON && (
            <div data-type="instructions">
              <div className={cn.instructions}>
                <Markdown markdown={meta.description} />
              </div>
            </div>
          )}

          <div data-type="css">
            <div className={editorCN}>
              <Editor
                beforeMount={handleEditorBeforeMount}
                defaultLanguage="css"
                defaultValue={baseCSS}
                options={EDITOR_OPTIONS}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                onValidate={handleEditorValidation}
                readonly={isSuccessful}
                theme="vs-dark"
              />

              {viewAuthorAnswer && <div className={cn.overlay} />}
            </div>
          </div>

          {isSuccessful && (
            <div className={cn.answer}>
              <button className={toggleCN} onClick={handleViewAuthorAnswer}>
                {viewAuthorAnswer ? (
                  <div className="toggle-svg">
                    <SVG icon="circle-check" />
                  </div>
                ) : (
                  <div className="toggle-svg">
                    <SVG icon="circle" />
                  </div>
                )}
                <span>View Author Answer</span>
              </button>
            </div>
          )}
        </div>

        <div className={cn.right}>
          <div data-type="output">
            <div className={cn.output}>
              <div
                id={userID}
                ref={userOutputRef}
                dangerouslySetInnerHTML={{ __html: outputHTML }}
              />
            </div>
          </div>

          <div data-type="expected">
            <div className={cn.output}>
              <div
                id={authorID}
                ref={authorOutputRef}
                dangerouslySetInnerHTML={{ __html: outputHTML }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={cn.controls}>
        <div className={cn.buttons}>
          {isSuccessful ? (
            <>
              {showContinue && (
                <button className="button is-outlined" onClick={handleContinue}>
                  <span className="button-label">Continue</span>
                </button>
              )}
            </>
          ) : (
            <>
              <button
                className="button is-outlined"
                onClick={handleCheckUserCode}
                disabled={somethingIsLoading}
              >
                <span className="button-label">Check Code</span>
              </button>

              {showContinue && (
                <button
                  className="button is-plain"
                  onClick={handleSkip}
                  disabled={somethingIsLoading}
                >
                  <span className="button-label">Skip</span>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {userErrors.length > 0 && !isSuccessful && (
        <div className="notification is-alert">
          <p>
            {userErrors.length === 1
              ? "An error remains:"
              : "A few errors remain:"}
          </p>
          <ul>
            {userErrors.map((error) => (
              <li key={error}>
                <ReactMarkdown>{error}</ReactMarkdown>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

Code.propTypes = {
  id: PropTypes.string,
  metaJSON: PropTypes.string,
  authorCSS: PropTypes.string,
  authorJSON: PropTypes.string,
  baseCSS: PropTypes.string,
  baseHTML: PropTypes.string,
  hiddenCSS: PropTypes.string,
  index: PropTypes.number,
};

export default Code;
