import { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import PropTypes from "prop-types";

import Markdown from "@/components/types/Markdown";
import cn from "./Example.module.css";
import { hashCode, prependCSS } from "@/components/utils";
import classNames from "classnames";
import SVG from "@/components/elements/SvgIcon";
import Output from "./Output";

const EDITOR_OPTIONS = {
  colorDecorators: true,
  fontSize: 16,
  hideCursorInOverviewRuler: true,
  lineNumbers: "off",
  minimap: {
    enabled: false,
  },
  overviewRuler: false,
  overviewRulerBorder: true,
  padding: {
    top: 20,
  },
  readOnly: true,
  renderLineHighlight: "none",
  renderOverviewRuler: false,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
  scrollBeyondLastLine: false,
  tabSize: 2,
};

const buildBaseCSSArr = (json, choices = {}) => {
  if (!json) {
    return [];
  }

  const blocks = JSON.parse(json);

  return blocks.map((block) => {
    const content = block.rules.map((rule) => {
      if (`${block.selector} ${rule.property}` in choices) {
        const choice = choices[`${block.selector} ${rule.property}`];
        return `${rule.property}: ${rule.prefix || ""}${choice};`;
      }

      const value = rule.defaultValue || rule.options[0].value;

      return `${rule.property}: ${rule.prefix || ""}${value};`;
    });

    return `${block.selector} {
  ${content.join("\n  ")}
}`;
  });
};

const buildAdvancedList = (list) => {
  return list.map((advanced) => {
    const { options } = advanced;

    return {
      chosen: options[0].label || options[1].label,
      options,
    };
  });
};

const buildAdvancedCSS = (list) => {
  return list.map((advanced) => {
    const { chosen, options } = advanced;

    if (chosen) {
      const option = options.find((item) => item.label === chosen);
      return option.css;
    }

    return `${options[0].css}\n`;
  });
};

const buildSliders = (obj) => {
  const { rule, description, sliders } = obj;

  const controls = sliders.map((item) => {
    const [min, max, step] = item.range;

    return {
      chosen: item.defaultValue,
      target: item.target,
      label: item.label,
      suffix: item.suffix,
      min,
      max,
      step,
    };
  });

  return {
    rule,
    description,
    controls,
  };
};

const buildSliderCSS = (obj) => {
  let output = "";

  const { rule, sliders } = obj;

  if (!sliders) {
    return "";
  }

  let finalRule = rule;

  sliders.forEach((slider) => {
    const { target, defaultValue } = slider;
    finalRule = finalRule.replaceAll(target, defaultValue);
  });

  output += finalRule;

  return output;
};

function ExampleWithControls({
  id,
  baseCSS,
  baseHTML,
  controlsJSON,
  advancedJSON,
  slidersJSON,
  metaJSON,
  hiddenCSS,
  hiddenHTML,
}) {
  const baseID = `example-${hashCode(id)}`;
  const baseStyleRef = useRef();
  const hiddenStyleRef = useRef();
  const advancedStyleRef = useRef();
  const slidersStyleRef = useRef();
  const outputRef = useRef();
  const sourceHTML = baseHTML || hiddenHTML;
  const displayedHTML = sourceHTML.replace(/ data-label="[a-zA-Z ]+"/gm, "");
  const [baseCSSArr, setBaseCSSArr] = useState(buildBaseCSSArr(controlsJSON));
  const [toggles, setToggles] = useState([]);
  const [advancedCSS, setAdvancedCSS] = useState(
    advancedJSON ? buildAdvancedCSS(JSON.parse(advancedJSON)) : [],
  );
  const [advancedList, setAdvancedList] = useState(
    advancedJSON ? buildAdvancedList(JSON.parse(advancedJSON)) : [],
  );
  const [sliderCSS, setSliderCSS] = useState(
    slidersJSON ? buildSliderCSS(JSON.parse(slidersJSON)) : "",
  );
  const [sliders, setSliders] = useState(
    slidersJSON ? buildSliders(JSON.parse(slidersJSON)) : {},
  );
  const [range, setRange] = useState({});
  const [choices, setChoices] = useState({});
  const [outputHTML, setOutputHTML] = useState(hiddenHTML || baseHTML);
  const [outputWidth, setOutputWidth] = useState(100);
  const meta = metaJSON ? JSON.parse(metaJSON) : {};

  function forceUpdate() {
    setOutputHTML((old) => {
      return `${old} `;
    });
  }

  const handleResize = (event) => {
    setOutputWidth(event.target.value);
  };

  const handleAdvancedClick = (index, newValue) => {
    const newList = [...advancedList];
    newList[index].chosen = newValue;
    setAdvancedList(newList);
  };

  const handleToggleClick = (selector, property, newValue) => {
    // Update current
    const toggle = toggles.find(
      (obj) => obj.selector === selector && obj.property === property,
    );

    if (toggle) {
      toggle.currentValue = newValue;
    }

    // Update the choices
    setChoices((oldChoices) => {
      return {
        ...oldChoices,
        [`${selector} ${property}`]: newValue,
      };
    });
  };

  const handleRangeChange = (selector, property, event) => {
    setRange((old) => {
      return {
        ...old,
        current: event.target.value,
      };
    });

    setChoices((oldChoices) => {
      return {
        ...oldChoices,
        [`${selector} ${property}`]: event.target.value,
      };
    });
  };

  const handleSliderChange = (event, index) => {
    setSliders((old) => {
      const copy = { ...old };
      copy.controls[index].chosen = event.target.value;
      return copy;
    });
  };

  useEffect(() => {
    setBaseCSSArr(buildBaseCSSArr(controlsJSON, choices));

    if (meta.force) {
      forceUpdate();
    }
  }, [choices, controlsJSON, meta.force]);

  useEffect(() => {
    setAdvancedCSS(buildAdvancedCSS(advancedList));
  }, [advancedList]);

  useEffect(() => {
    let output = "";

    if (!sliders.rule) {
      return "";
    }

    let finalRule = sliders.rule;

    sliders.controls?.forEach((slider) => {
      const { target, chosen } = slider;
      finalRule = finalRule.replaceAll(target, chosen);
    });

    output += finalRule;
    setSliderCSS(output);
  }, [sliders]);

  useEffect(() => {
    if (hiddenStyleRef.current) {
      hiddenStyleRef.current.innerHTML = prependCSS(hiddenCSS, baseID);
    }
  }, [hiddenCSS, baseID]);

  useEffect(() => {
    if (advancedStyleRef.current) {
      advancedStyleRef.current.innerHTML = prependCSS(
        advancedCSS.join(" "),
        baseID,
      );
    }
  }, [advancedCSS, baseID]);

  useEffect(() => {
    if (slidersStyleRef.current) {
      slidersStyleRef.current.innerHTML = prependCSS(sliderCSS, baseID);
    }
  }, [sliderCSS, baseID]);

  useEffect(() => {
    if (!controlsJSON) {
      return;
    }

    const blocks = JSON.parse(controlsJSON);
    const toggles = [];

    blocks.forEach((block) => {
      block.rules.map((rule) => {
        if (rule.options) {
          // Create a "toggle" object with this shape:
          // {
          //   selector: ".flex-container",
          //   property: "display",
          //   options: [
          //     {value: "block", description: "Foo bar"},
          //     {value: "flex", description: "Bar foo"}
          //   ],
          //   currentValue: "block",
          // }

          const key = `${block.selector} ${rule.property}`;
          const selector = block.selector;
          const description = rule.description || block.description;
          const property = rule.property;
          const options = rule.options;
          const currentValue = rule.defaultValue || rule.options[0].value;

          toggles.push({
            key,
            selector,
            description,
            property,
            options,
            currentValue,
          });
        } else if (rule.range) {
          const [min, max, step] = rule.range;

          setRange({
            selector: block.selector,
            property: rule.property,
            current: rule.defaultValue,
            min,
            max,
            step,
          });
        }
      });
    });

    setToggles(toggles);
  }, [controlsJSON]);

  useEffect(() => {
    if (baseStyleRef.current) {
      const fromEditor = prependCSS(baseCSSArr.join("\n\n"), baseID);

      if (baseCSS) {
        const fromMe = prependCSS(baseCSS, baseID);
        baseStyleRef.current.innerHTML = `${fromEditor} ${fromMe}`;
      } else {
        baseStyleRef.current.innerHTML = fromEditor;
      }
    }
  }, [baseCSS, baseCSSArr, baseID]);

  const mainCN = classNames({
    [cn.main]: true,
    [cn["hide-html"]]: !baseHTML,
    [cn.horizontal]: meta.layout === "horizontal",
  });

  const codeCN = classNames({
    [cn.code]: true,
    [cn["code-with-controls"]]: true,
  });

  let cssEditorValue = baseCSS || advancedCSS.join(" ") || sliderCSS;

  if (baseCSSArr.length > 0) {
    cssEditorValue = `${baseCSSArr.join("\n\n")}

${cssEditorValue}`;
  }

  const prettifiedCSS = cssEditorValue;

  const outputStyle = meta.resize
    ? {
        width: `${outputWidth}%`,
      }
    : {};

  return (
    <div className={mainCN}>
      <style ref={hiddenStyleRef} />
      <style ref={baseStyleRef} />
      <style ref={advancedStyleRef} />
      <style ref={slidersStyleRef} />

      <div className={codeCN}>
        <div className={cn.left}>
          <div className={cn.controls} data-type="controls">
            <p className={cn.info}>
              Use these controls to play with the example
            </p>

            {sliders.description && (
              <div className={cn.description}>
                <Markdown markdown={sliders.description} />
              </div>
            )}

            {sliders.controls?.map((control, index) => {
              return (
                <div key={control} className={cn.range}>
                  <div className={cn.description}>
                    <Markdown markdown={control.label} />
                  </div>

                  <div className={cn["range-input"]}>
                    <input
                      type="range"
                      min={control.min}
                      max={control.max}
                      step={control.step}
                      value={control.chosen}
                      onChange={(event) => handleSliderChange(event, index)}
                    />

                    <p className={cn["range-edges"]}>
                      <span>{control.min}</span>
                      <span>{control.max}</span>
                    </p>
                  </div>

                  <code className={cn["range-value"]}>
                    {control.chosen}
                    {control.suffix}
                  </code>
                </div>
              );
            })}

            {advancedList.map((advancedItem, index) => {
              let advancedDescription = null;

              return (
                <>
                  <div key={advancedItem} className={cn.options}>
                    {advancedItem.options.map((option) => {
                      const checked = option.label === advancedItem.chosen;

                      const toggleCN = classNames({
                        ["toggle"]: true,
                        ["toggle-checked"]: checked,
                      });

                      if (option.text) {
                        return (
                          <Markdown
                            key={option.text}
                            className="toggle-text"
                            markdown={option.text}
                          />
                        );
                      }

                      if (checked && option.description) {
                        advancedDescription = (
                          <div className={cn.description}>
                            <Markdown markdown={option.description} />
                          </div>
                        );
                      }

                      return (
                        <code
                          key={option}
                          className={toggleCN}
                          onClick={() =>
                            handleAdvancedClick(index, option.label)
                          }
                        >
                          <Markdown markdown={option.label} />
                        </code>
                      );
                    })}
                  </div>
                  {advancedDescription}
                </>
              );
            })}

            {toggles.map((toggle) => {
              let description = null;

              const currentItem = toggle.options.find(
                (obj) => obj.value === toggle.currentValue,
              );

              if (currentItem?.description) {
                description = (
                  <div className={cn.description}>
                    <Markdown markdown={currentItem.description} />
                  </div>
                );
              }

              return (
                <>
                  {toggle.description && (
                    <div className={cn["toggle-description"]}>
                      <Markdown markdown={toggle.description} />
                    </div>
                  )}

                  <div key={toggle} className={cn.options}>
                    {toggle.options.map((option) => {
                      if (option.text) {
                        return (
                          <Markdown
                            key={option.text}
                            className="toggle-text"
                            markdown={option.text}
                          />
                        );
                      }

                      const checked = option.value === toggle.currentValue;

                      const toggleCN = classNames({
                        ["toggle"]: true,
                        ["toggle-checked"]: checked,
                      });

                      const newValue = option.value;

                      return (
                        <code
                          key={option}
                          className={toggleCN}
                          onClick={() =>
                            handleToggleClick(
                              toggle.selector,
                              toggle.property,
                              newValue,
                            )
                          }
                        >
                          {checked ? (
                            <div className="toggle-svg">
                              <SVG icon="circle-check" />
                            </div>
                          ) : (
                            <div className="toggle-svg">
                              <SVG icon="circle" />
                            </div>
                          )}

                          <span>{option.label || option.value}</span>
                        </code>
                      );
                    })}
                  </div>
                  {description}
                </>
              );
            })}

            {range.current && (
              <div className={cn.range}>
                <div className={cn["range-input"]}>
                  <input
                    type="range"
                    min={range.min}
                    max={range.max}
                    step={range.step}
                    value={range.current}
                    onChange={(event) =>
                      handleRangeChange(range.selector, range.property, event)
                    }
                  />

                  <p className={cn["range-edges"]}>
                    <span>{range.min}</span>
                    <span>{range.max}</span>
                  </p>
                </div>

                <code className={cn["range-value"]}>
                  {range.current}
                  {range.suffix}
                </code>
              </div>
            )}
          </div>

          <div className={`monaco-css ${cn.css}`} data-type="css">
            <Editor
              defaultLanguage="css"
              value={prettifiedCSS}
              options={EDITOR_OPTIONS}
              theme="github-dark"
            />
          </div>

          <div className={`monaco-html ${cn.html}`} data-type="html">
            <Editor
              defaultLanguage="html"
              defaultValue={displayedHTML}
              options={EDITOR_OPTIONS}
              readonly={true}
              theme="vs-dark"
            />
          </div>
        </div>

        <div className={cn.right}>
          {meta.resize && (
            <div className={`${cn.controls} ${cn.resize}`}>
              <div className={cn.info}>Resize the viewport</div>
              <input
                type="range"
                min="50"
                max="100"
                value={outputWidth}
                onChange={handleResize}
              />
            </div>
          )}

          <div
            className={cn.output}
            data-type="output"
            style={outputStyle}
            ref={outputRef}
          >
            <div className={cn.scroller}>
              <Output baseID={baseID} outputHTML={outputHTML} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ExampleWithControls.propTypes = {
  id: PropTypes.string,
  authorCSS: PropTypes.string,
  authorJSON: PropTypes.string,
  baseCSS: PropTypes.string,
  baseHTML: PropTypes.string,
  hiddenHTML: PropTypes.string,
  advancedJSON: PropTypes.string,
  controlsJSON: PropTypes.string,
  slidersJSON: PropTypes.string,
  metaJSON: PropTypes.string,
  hiddenCSS: PropTypes.string,
  isCurrent: PropTypes.bool,
  kind: PropTypes.oneOf(["example", "exercise"]),
  onSuccess: PropTypes.func,
  onContinue: PropTypes.func,
  onSkip: PropTypes.func,
};

export default ExampleWithControls;
